* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

.modal {
  --max-height: 98%;
  --border-radius: 10px;
}

a:visited,
a:link,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}
